/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch, useSelector } from "react-redux";
import MapView from "../map/core/MapView";
import MapSelectedDevice from "../map/main/MapSelectedDevice";
import MapAccuracy from "../map/main/MapAccuracy";
import MapGeofence from "../map/MapGeofence";
import MapCurrentLocation from "../map/MapCurrentLocation";
import PoiMap from "../map/main/PoiMap";
import MapPadding from "../map/MapPadding";
import { devicesActions } from "../store";
import MapDefaultCamera from "../map/main/MapDefaultCamera";
import MapLiveRoutes from "../map/main/MapLiveRoutes";
import MapPositions from "../map/MapPositions";
import MapOverlay from "../map/overlay/MapOverlay";
import MapGeocoder from "../map/geocoder/MapGeocoder";
import MapScale from "../map/MapScale";
import MapNotification from "../map/notification/MapNotification";
import useFeatures from "../common/util/useFeatures";

const MainMap = ({
  filteredDevices,
  filteredPositions,
  selectedPosition,
  onEventsClick,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const eventsAvailable = useSelector((state) => !!state.events.items.length);

  const selectedDeviceId = useSelector((state) => state.devices.selectedId);
  const devices = useSelector((state) => state.devices.items);
  const groups = useSelector((state) => state.groups.items);

  const devicesByGroup = (groupId) => {
    return Object.values(devices).filter((device) => {
      return [device.groupId].some((s) => s && s === groupId);
    }).length;
  };

  const features = useFeatures();

  const onMarkerClick = useCallback(
    (_, deviceId) => {
      dispatch(devicesActions.selectId(deviceId));
    },
    [dispatch]
  );

  const [deviceBlocked, setDeviceBlocked] = useState(false);

  useEffect(() => {
    setDeviceBlocked(false);
    if (filteredPositions && selectedDeviceId) {
      const selectedPosition = filteredPositions.find(
        (position) => selectedDeviceId && position.deviceId === selectedDeviceId
      );

      if (selectedPosition) {
        if (selectedPosition.attributes.hasOwnProperty("blocked")) {
          setDeviceBlocked(selectedPosition.attributes.blocked);
        }
      }
    }
  }, [filteredPositions, selectedDeviceId]);

  return (
    <>
      <MapView>
        <MapOverlay />
        <MapGeofence />
        <MapAccuracy positions={filteredPositions} />
        <MapLiveRoutes />
        <MapPositions
          positions={filteredPositions}
          onClick={onMarkerClick}
          selectedPosition={selectedPosition}
          showStatus
        />
        <MapDefaultCamera />
        <MapSelectedDevice />
        <PoiMap />
      </MapView>
      <MapScale />
      <MapCurrentLocation />
      <MapGeocoder />
      {!features.disableEvents && (
        <MapNotification enabled={eventsAvailable} onClick={onEventsClick} />
      )}
      {desktop && (
        <MapPadding left={parseInt(theme.dimensions.drawerWidthDesktop, 10)} />
      )}
      {deviceBlocked && selectedDeviceId && (
        <Tooltip title="Bloqueado" placement="right">
          <LockIcon
            style={{
              position: "absolute",
              bottom: desktop ? 8 : 60,
              left: desktop ? 380 : 0,
              height: 92,
              width: 92,
              color: "red",
            }}
          />
        </Tooltip>
      )}
      <div
        style={{
          position: "absolute",
          top: desktop ? 12 : 65,
          left: desktop ? 380 : 8,
          height: 130,
          width: 40,
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          padding: 5,
          backgroundColor: "transparent",
        }}
      >
        {" "}
        <Tooltip title="Conectado" placement="right">
          <span
            style={{
              flex: 1,
              fontSize: 13,
              width: 40,
              height: 40,
              alignItems: "center",
              textAlign: "center",
              padding: 5,
              marginBottom: 3,
              borderRadius: 5,
              color: "white",
              fontWeight: "bold",
              backgroundColor: "green",
            }}
          >
            {filteredDevices.filter((d) => d.status === "online").length}
          </span>
        </Tooltip>
        <Tooltip title="Em Movimento" placement="right">
          <span
            style={{
              flex: 1,
              fontSize: 13,
              width: 40,
              height: 40,
              alignItems: "center",
              textAlign: "center",
              padding: 5,
              marginBottom: 3,
              borderRadius: 5,
              color: "white",
              fontWeight: "bold",
              backgroundColor: "blue",
            }}
          >
            {
              filteredPositions
                .map((p) => {
                  if (p?.attributes.hasOwnProperty("motion")) {
                    return p.attributes.motion;
                  }
                  return false;
                })
                .filter((f) => f === true).length
            }
          </span>
        </Tooltip>
        <Tooltip title="Desconectado" placement="right">
          <span
            style={{
              flex: 1,
              fontSize: 13,
              width: 40,
              height: 40,
              alignItems: "center",
              textAlign: "center",
              padding: 5,
              marginBottom: 3,
              borderRadius: 5,
              color: "white",
              fontWeight: "bold",
              backgroundColor: "red",
            }}
          >
            {filteredDevices.filter((d) => d.status === "offline").length}
          </span>
        </Tooltip>
        <Tooltip title="Todos" placement="right">
          <span
            style={{
              flex: 1,
              fontSize: 13,
              width: 40,
              height: 40,
              alignItems: "center",
              textAlign: "center",
              padding: 5,
              marginBottom: 3,
              borderRadius: 5,
              color: "white",
              fontWeight: "bold",
              backgroundColor: "purple",
            }}
          >
            {filteredDevices.length}
          </span>
        </Tooltip>
        {Object.values(groups).map((item, index) => {
          return (
            <Tooltip title={item.name} placement="right">
              <span
                style={{
                  flex: 1,
                  fontSize: 13,
                  width: 40,
                  height: 40,
                  alignItems: "center",
                  textAlign: "center",
                  padding: 5,
                  marginBottom: 3,
                  borderRadius: 5,
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: index % 2 !== 0 ? "orange" : "gray",
                }}
              >
                {devicesByGroup(item.id)}
              </span>
            </Tooltip>
          );
        })}
      </div>
    </>
  );
};

export default MainMap;
