/* eslint-disable */
import React from "react";
import { Snackbar } from "@mui/material";
import { useTranslation } from "./LocalizationProvider";
import { snackBarDurationLongMs } from "../util/duration";

const SuccessSendDialog = ({ open, onResult }) => {
  const t = useTranslation();
  return (
    <Snackbar
      open={open === 200 || open === 202}
      onClose={() => onResult(0)}
      autoHideDuration={snackBarDurationLongMs}
      message={open === 202 ? t("commandQueued") : t("commandSent")}
    />
  );
};

export default SuccessSendDialog;
